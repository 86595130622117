import React, { useEffect, useState } from 'react'
import styles from 'styles/dashboard/dashboard.module.scss'
import { useEmbedUrlRequest } from 'hooks/dashboard/dashboardHooks'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardUrlSelector, pageSelector, selectDateSelector, selectStationSelector } from 'modules/pages/selectors'
import { useCookie, useWindowSize } from 'react-use'
import AppHeader from 'components/parts/Header'
import { AppDispatch } from 'store/configureStore'
import { getFormatDate } from 'common/datetimeUtil'
import { DTIME_FORMAT } from 'constants/constants'
import { setDashboardUrl, setSelectStation } from 'modules/pages/reducers'
import { useGetElementProperty } from 'hooks/common/getElementProperty'
import { menuMasterSelector, stationMasterSelector } from 'modules/masters/selectors'
import { useNavigator } from 'hooks/common/routingHooks'
import { navigateTop } from 'common/commonUtil'

/**
 * ダッシュボード画面
 * @constructor
 */
const Dashboard: React.FC = () => {
    // 駅マスタ
    const stationList = useSelector(stationMasterSelector)
    const menuList = useSelector(menuMasterSelector)

    const onClick = useEmbedUrlRequest()

    const pageState = useSelector(pageSelector)
    const dispatch: AppDispatch = useDispatch()
    const [embedUrl, setEmbedUrl] = useState<string>('')

    const [station, updateCookie] = useCookie('station')

    const navigator = useNavigator()
    const [isFirstAccess, setIsFirstAccess] = useState(true)
    const height = useWindowSize().height
    const [iframeHeight, setIframeHeight] = useState(0)

    useEffect(() => {
        // クッキーをセット
        if (selectStation && selectStation.stationName) {
            updateCookie(JSON.stringify(selectStation))
        } else if (station) {
            // cookieに何も入ってない＝文字列がない＝falseと判定される
            // 文字列型からオブジェクト型に変換して保持
            dispatch(setSelectStation(JSON.parse(station)))
        } else {
            if (stationList.length) {
                dispatch(setSelectStation(stationList[0]))
            }
        }
        // 初回アクセス時のみ実行
        if (isFirstAccess && menuList.length > 0) {
            navigateTop(navigator, menuList[0])
            setIsFirstAccess(false)
        }

        const initAsync = async () => {
            if (menuList.length > 0 && pageState.selectMenu.dashboardId) await onClick()
        }
        initAsync().then()
    }, [pageState.selectMenu, menuList])

    useEffect(() => {
        getIframeHeight()
    }, [height])

    const getIframeHeight = () => {
        // window全体の高さからヘッダーの高さを引いた値
        setIframeHeight(height - getElementProperty('height'))
    }

    const targetRef = React.useRef(null)
    const { getElementProperty } = useGetElementProperty<HTMLDivElement>(targetRef)

    const dashboardUrl = useSelector(dashboardUrlSelector)
    const selectStation = useSelector(selectStationSelector)
    const date = useSelector(selectDateSelector)

    useEffect(() => {
        // ダッシュボードURLが取得できなかったもしく選択している駅コードが取得できなかった場合iframeを表示しないように変更
        if (!dashboardUrl || (selectStation && !selectStation.stationCode)) {
            setEmbedUrl('')
        } else {
            const fDate = getFormatDate(date, DTIME_FORMAT)
            const filteredUrl = `${dashboardUrl}#p.stationCodeName=${selectStation.stationCode}&p.date=${fDate}`
            setEmbedUrl(filteredUrl)
        }
    }, [dashboardUrl, selectStation, date])

    // iframeに入っているembedUrlをクリアする
    // return : コンポーネントがunmountされたときに実行される関数を記述できる
    useEffect(() => {
        return () => {
            setEmbedUrl('')
            dispatch(setDashboardUrl(''))
        }
    }, [])

    return (
        <React.Fragment>
            <div style={{ overflowY: 'hidden' }}>
                <AppHeader headerRef={targetRef} />
                <div className={styles.dashboardFrame} style={{ height: iframeHeight }}>
                    {/* embedUrlがない場合iframe自体を非表示にする */}
                    <iframe src={embedUrl} width="100%" height={iframeHeight} frameBorder="no" />
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Dashboard)
