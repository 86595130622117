/**
 * マスタ
 */
export interface MasterState {
    menu: MenuMaster[]
    station: StationMaster[]
}

/**
 * メニューマスタ
 */
export interface MenuMaster {
    menuId: number
    dashboardId: string
    dashboardName: string
    menuName: string
    order: number
}
export const InitMenu: MenuMaster = {
    menuId: 0,
    dashboardId: '',
    dashboardName: '',
    menuName: '',
    order: 0,
}

/**
 * 駅マスタ
 */
export interface StationMaster {
    routeCode: number
    routeName: string
    stationCode: string
    stationName: string
    order: string
}
