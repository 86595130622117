import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import React from 'react'

//アプリの共通ヘッダー
const UsePageTracking = () => {
    const location = useLocation()

    // ページ遷移されるたびにlocationの中身が変わる
    useEffect(() => {
        // Google Analytics 測定 ID を入力して設定
        ReactGA.initialize('G-TJDRL331BP')
        ReactGA.send({
            hitType: 'pageview',
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)ドメイン以降の値
            page: location.pathname + location.search,
        })
    }, [location])

    return <></>
}

export default React.memo(UsePageTracking)
