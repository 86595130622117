// 画面上に表示する文言を定義
// 別にlabelタグ専用というわけではなく、画面上に表示するちょっとした文言の定義
// かなり多くなるならコンポーネントとかページ単位で分割してもいい

// ログイン画面
export const signInLabels = {
    SIGN_IN: 'サインイン',
    MAIL_ADDRESS: 'メールアドレス',
    PASSWORD: 'パスワード',
    SIGN_IN_BUTTON: 'ログイン',
    LINK_RESET_TITLE: 'パスワードをお忘れの方は',
    LINK_RESET_HERE: 'こちら',
    PASSWORD_CODE: '認証コード',
    NEW_PASSWORD: '新しいパスワード',
    CONFIRM_PASSWORD: '新しいパスワード再入力',
}

// 駅選択
export const stationSelectLabel = {
    stationSelect: '確認する駅を選択してください。',
    decide: '決定',
}

// 駅選択
export const sideMenuLabel = {
    stationSelect: '駅選択',
    logout: 'ログアウト',
}
