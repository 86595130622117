// 共通.日付の書式指定文字列
export const DTIME_FORMAT = 'yyyy/MM/dd HH:mm:ss'

// 入力チェック桁数
export const digitLimits = {
    MAIL_ADDRESS: { MIN: 1, MAX: 256 },
    PASSWORD: { MIN: 8, MAX: 16 },
    USER_NAME: { MIN: 1, MAX: 100 },
}

// 共通.Httpステータスコード
export const httpStatusCode = {
    OK: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
}

// 認証状態
export const authState = {
    // 未認証
    HAS_NOT_AUTHED: 0,
    // 認証済み
    HAS_AUTHED: 1,
    // 保留中
    SUSPEND: 2,
}

export const cognitoErrorCode = {
    NOT_AUTHORIZED: 'Incorrect username or password.',
    LIMIT_EXCEEDED: 'Password attempts exceeded',
}

// エラーレスポンスを固定値クラスで指定することでコード管理の手間を省く
export const cognitoChangePasswordExceptions = {
    // 無効なコードが入力された場合に起こる。
    // 注) username が存在しない・無効化されている場合にも起こる。
    CODE_MISMATCH: 'CodeMismatchException',
    // password が6文字未満など Cognito 側で正しくパースできない場合（バリデーションエラー）に起こる。
    INVALID_PARAM: 'InvalidParameterException',
    // ユーザープールのポリシーで設定したパスワードの強度を満たさない場合に起こる。
    INVALID_PWD: 'InvalidPasswordException',
    // 認証コードの有効期限切れ（1時間
    EXPIRED_CODE: 'ExpiredCodeException',
    // パスワード変更試行回数制限超え（1時間に5回まで）
    LIMIT_EXCEEDED: 'LimitExceededException',
    // システムエラー（500）
    INTERNAL_ERROR: 'InternalErrorException',
}
