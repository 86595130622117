import LuxonUtils from '@date-io/luxon'
import { StylesProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Dashboard from 'components/pages/Dashboard'
import SignIn from 'components/pages/SignIn'
import StationSelect from 'components/pages/StationSelect'
import CommonDialog from 'components/parts/CommonDialog'
import GuestRoute from 'components/parts/GuestRoute'
import Loader from 'components/parts/Loader'
import PasswordConfirmDialog from 'components/parts/PasswordConfirmDialog'
import PrivateRoute from 'components/parts/PrivateRoute'
import SendCodeChangePasswordDialog from 'components/parts/SendCodeChangePasswordDialog'
import UsePageTracking from 'components/parts/UsePageTracking'
import { authState } from 'constants/constants'
import { messages, words } from 'constants/messages'
import { pagePath } from 'constants/paths'
import { useAuthState } from 'hooks/common/authHooks'
import { useMasterDataRequest } from 'hooks/master/masterHooks'
import {
    closeGlobalConfirmationDialog,
    closeGlobalDialog,
    setPasswordConfirmDialogOpen,
    setPasswordDialogOpen,
} from 'modules/pages/reducers'
import {
    confirmationDialogSelector,
    dialogSelector,
    passwordDialogConfirmSelector,
    passwordDialogOpenSelector,
} from 'modules/pages/selectors'
import { userSelector } from 'modules/users/selectors'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import { AppDispatch } from 'store/configureStore'
import styles from 'styles/app.module.scss'

// アプリの中で最上位の配置を担う
// ページルーティングの設定もここで
const App: React.FC = () => {
    const dispatch: AppDispatch = useDispatch()
    const dialogState = useSelector(dialogSelector)
    const confirmationDialogState = useSelector(confirmationDialogSelector)
    const users = useSelector(userSelector)
    const passwordDialogState = useSelector(passwordDialogOpenSelector)
    const passwordConfirmDialogState = useSelector(passwordDialogConfirmSelector)

    const getMaster = useMasterDataRequest()

    // 仕様確認中

    // Cookie無効時のエラーダイアログ表示用変数
    const [openCookieDialog, setOpenCookieDialog] = useState(false)

    // Cookie状態確認
    useEffect(() => {
        if (!navigator.cookieEnabled) {
            //cookie無効の場合、Cookie有効にするよう促すダイアログを表示
            // ダイアログを呼び出す（閉じれないようにする）
            // Stateを更新するやつを書く
            setOpenCookieDialog(true)
        }
    })

    // 認証状態チェック
    useAuthState()

    useEffect(() => {
        // ユーザー認証済みの場合にマスタ取得実行
        if (users && users.hasAuthed == authState.HAS_AUTHED) {
            const initAsync = async () => {
                await getMaster()
            }
            initAsync().then()
        }
    }, [users.hasAuthed])

    return (
        <StylesProvider injectFirst>
            {/*Material UIのDatePickerをLuxonで使用したいためこれで囲う*/}
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <div className={styles.app}>
                    <div className={styles.mainContent}>
                        <Switch>
                            {/* 未認証の時のみ表示 */}
                            <GuestRoute exact path={pagePath.SIGN_IN} key={pagePath.SIGN_IN} component={SignIn} />
                            {/* 認証済みの時のみ表示 */}
                            <PrivateRoute
                                exact
                                path={pagePath.DASHBOARD}
                                key={pagePath.DASHBOARD}
                                component={Dashboard}
                            />
                            <PrivateRoute
                                exact
                                path={pagePath.SELECT_STATION}
                                key={pagePath.SELECT_STATION}
                                component={StationSelect}
                            />
                            {/* 存在しないパスの時はログインへ */}
                            <GuestRoute component={SignIn} />
                        </Switch>
                    </div>
                    {/*Loader*/}
                    <Loader />

                    {/*パスワードお問い合わせダイアログ*/}
                    <SendCodeChangePasswordDialog
                        open={passwordDialogState}
                        onClose={() => dispatch(setPasswordDialogOpen(false))}
                    />

                    {/*新パスワードダイアログ*/}
                    <PasswordConfirmDialog
                        open={passwordConfirmDialogState.open}
                        onClose={() => dispatch(setPasswordConfirmDialogOpen({ open: false, userEmail: '' }))}
                        userEmail={passwordConfirmDialogState.userEmail}
                    />

                    {/*表示するだけの通知ポップアップ*/}
                    <CommonDialog
                        open={dialogState.open}
                        title={dialogState.title}
                        mainText={dialogState.mainText}
                        cancelLabel={words.CLOSE}
                        onClose={() => dispatch(closeGlobalDialog())}
                        isNote
                    />

                    {/*確認ポップアップ*/}
                    <CommonDialog
                        open={confirmationDialogState.open}
                        title={confirmationDialogState.title}
                        mainText={confirmationDialogState.mainText}
                        continueLabel={confirmationDialogState.continueLabel}
                        cancelLabel={confirmationDialogState.cancelLabel}
                        onClickContinue={confirmationDialogState.onClickContinue}
                        onClose={() => dispatch(closeGlobalConfirmationDialog())}
                    />

                    {/* Cookie無効時はダイアログ表示させる */}
                    <CommonDialog
                        open={openCookieDialog}
                        title={''}
                        mainText={messages.COOKIE_INVALID_ERROR()}
                        cancelLabel={''}
                        onClose={() => {
                            return
                        }}
                        isNote
                    />
                </div>
                <UsePageTracking />
            </MuiPickersUtilsProvider>
        </StylesProvider>
    )
}

export default App
