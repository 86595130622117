import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import * as Amplify from 'aws-amplify'
import { cognitoChangePasswordExceptions } from 'constants/constants'
import { signInLabels } from 'constants/labels'
import { messages, words } from 'constants/messages'
import { useStringState } from 'hooks/common/commonHooks'
import {
    setChangePasswordErrorMessage,
    setChangePasswordRestartErrorMessage,
    setChangePasswordSuccessMessage,
    setGlobalDialog,
    toggleLoader,
} from 'modules/pages/reducers'
import { changePasswordErrorMessageSelector } from 'modules/pages/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'store/configureStore'
import styles from 'styles/signIn/signInForms.module.scss'

interface Props {
    // 開閉状態
    open: boolean
    // Noのときのアクション
    onClose: () => void
    userEmail: string
}

/**
 * 新パスワード設定ダイアログ
 * @param props
 * @constructor
 */
export const PasswordConfrimDialog: React.FC<Props> = (props) => {
    const [authCode, setAuthCode] = useStringState('')
    const [newPassword, setNewPassword] = useStringState('')
    const [newPasswordConfirm, setNewPasswordConfirm] = useStringState('')
    const dispatch: AppDispatch = useDispatch()
    // 認証コード、新パス、確認用パス全て入力したとき再設定ボタンは活性化する
    const activeBtn = !(authCode.trim() && newPassword.trim() && newPasswordConfirm.trim())
    // パスワード変更失敗メッセージ
    const changePasswordErrorMessage = useSelector(changePasswordErrorMessageSelector)
    // パスワード入力欄を空白
    const resetInputArea = () => {
        setAuthCode.reset()
        setNewPassword.reset()
        setNewPasswordConfirm.reset()
    }

    // はじめからやり直すボタン押下時
    const returnSignIn = () => {
        dispatch(setChangePasswordSuccessMessage(''))
        dispatch(setChangePasswordErrorMessage(''))
        resetInputArea()
        props.onClose()
    }

    const setNewPasswordAsync = async (authCode: string, newPassword: string, newPasswordConfirm: string) => {
        dispatch(setChangePasswordErrorMessage(''))
        dispatch(setChangePasswordRestartErrorMessage(''))

        if (newPassword !== newPasswordConfirm) {
            // 新パスワードと確認用パスワードが一致しないとき、エラーメッセージ表示
            dispatch(setChangePasswordErrorMessage(messages.CHANGE_PASSWORD_ERROR_NOT_MATCH()))
            // パスワード変更に失敗した場合、パスワード入力欄は空にする
            resetInputArea()
        } else {
            dispatch(toggleLoader(true))
            await Amplify.Auth.forgotPasswordSubmit(props.userEmail, authCode, newPassword)
                .then(() => {
                    // ログイン画面に表示するパスワード変更成功メッセージをセット
                    dispatch(setChangePasswordSuccessMessage(messages.CHANGE_PASSWORD_SUCCESS()))
                    // パスワード変更失敗メッセージに空文字をセット
                    dispatch(setChangePasswordErrorMessage(''))
                    dispatch(setChangePasswordRestartErrorMessage(''))
                    // 入力欄を空白
                    resetInputArea()
                    // ダイアログを閉じる（同時にステートに保持していたメールアドレスを破棄）
                    props.onClose()
                })
                .catch((error) => {
                    console.log(error)
                    // 例外オブジェクトはError型なのだが、型的には存在しないはずの"code"という項目を参照する必要がある。
                    // 通常はifで型を判断したルート分岐をして型を確定させるが、今回はanyのままとした。aws-amplifyに問題がある。
                    switch (error?.code) {
                        case cognitoChangePasswordExceptions.CODE_MISMATCH:
                        case cognitoChangePasswordExceptions.INVALID_PARAM:
                        case cognitoChangePasswordExceptions.INVALID_PWD:
                            // 入力項目不備（認証コード・パラメータ・パスワード）
                            dispatch(setChangePasswordErrorMessage(messages.CHANGE_PASSWORD_ERROR_INCOMPLETE_INPUT()))
                            break
                        case cognitoChangePasswordExceptions.EXPIRED_CODE:
                            // 認証コードの有効期限切れ（1時間)
                            dispatch(
                                setChangePasswordRestartErrorMessage(messages.CHANGE_PASSWORD_ERROR_EXPIRED_CODE())
                            )
                            props.onClose()
                            break
                        case cognitoChangePasswordExceptions.LIMIT_EXCEEDED:
                            // パスワード変更試行回数制限超え（1時間に5回まで）
                            dispatch(
                                setChangePasswordRestartErrorMessage(messages.CHANGE_PASSWORD_ERROR_LIMIT_EXCEEDED())
                            )
                            props.onClose()
                            break
                        case cognitoChangePasswordExceptions.INTERNAL_ERROR:
                            // システムエラー（500）
                            dispatch(setGlobalDialog({ title: '', mainText: messages.SYSTEM_ERROR(), open: true }))
                            props.onClose()
                            break
                        default:
                            // その他（システムエラーダイアログを表示する。ただしパスワード変更ダイアログ自体は閉じない）
                            dispatch(setGlobalDialog({ title: '', mainText: messages.SYSTEM_ERROR(), open: true }))
                            break
                    }
                    // パスワード変更に失敗した場合、パスワード入力欄は空にする
                    resetInputArea()
                })
            dispatch(toggleLoader(false))
        }
    }

    return (
        <Dialog open={props.open}>
            <DialogContent>
                <DialogContentText>
                    <span className={styles.dialogText}>
                        {messages.CHANGE_PASSWORD_DIALOG_POLICY()}
                        {/* パスワード変更失敗メッセージ */}
                        {changePasswordErrorMessage && (
                            <p className={`${styles.changePasswordErrorText}`}>{changePasswordErrorMessage}</p>
                        )}
                        <div className={styles.inputPasswordArea}>
                            {/* 認証コード入力 */}
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={signInLabels.PASSWORD_CODE}
                                type="number"
                                autoComplete="email"
                                InputProps={{
                                    inputProps: { maxLength: 255 },
                                    classes: {
                                        notchedOutline: styles.inputTextFieldOutline,
                                    },
                                }}
                                value={authCode}
                                onChange={setAuthCode.update}
                            />
                        </div>
                        <div className={styles.inputPasswordArea}>
                            {/* 新パスワード入力 */}
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={signInLabels.NEW_PASSWORD}
                                type="password"
                                InputProps={{
                                    inputProps: { maxLength: 255 },
                                    classes: {
                                        notchedOutline: styles.inputTextFieldOutline,
                                    },
                                }}
                                value={newPassword}
                                onChange={setNewPassword.update}
                            />
                        </div>
                        <div className={styles.inputPasswordArea}>
                            {/* 確認用パスワード入力 */}
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={signInLabels.CONFIRM_PASSWORD}
                                type="password"
                                InputProps={{
                                    inputProps: { maxLength: 255 },
                                    classes: {
                                        notchedOutline: styles.inputTextFieldOutline,
                                    },
                                }}
                                value={newPasswordConfirm}
                                onChange={setNewPasswordConfirm.update}
                            />
                        </div>
                    </span>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.inputPasswordArea}>
                {/* はじめからやり直す*/}
                <Button
                    className={styles.returnButton}
                    align-items="left"
                    onClick={returnSignIn}
                    color="secondary"
                    autoFocus={true}
                >
                    {words.RETURN_SIGN_IN}
                </Button>
                {/* 再設定する*/}
                <Button
                    align-items="center"
                    className={styles.signInButton}
                    color="secondary"
                    autoFocus={true}
                    disabled={activeBtn}
                    onClick={() => setNewPasswordAsync(authCode, newPassword, newPasswordConfirm)}
                >
                    {words.RESET}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(PasswordConfrimDialog)
