import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as Amplify from 'aws-amplify'
import { Auth } from 'aws-amplify'
import { authState, cognitoErrorCode } from 'constants/constants'
import { setAuthState } from 'modules/users/reducers'
import { setGlobalDialog, setLoginErrorMessage, toggleLoader } from 'modules/pages/reducers'
import { pagePath } from 'constants/paths'
import { useNavigator } from './routingHooks'
import { messages } from 'constants/messages'
import { sendEvent } from 'common/googleAnalyticsUtil'

/**
 * 認証済みかどうかを取得してStoreに状態をセットする
 * 実質アプリの初期表示の1度しか行わないはず
 */
export const useAuthState = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        Auth.currentSession()
            .then((auth) => {
                // 認証状態をセット
                dispatch(setAuthState(auth.isValid() ? authState.HAS_AUTHED : authState.HAS_NOT_AUTHED))
            })
            .catch(() => {
                // 未認証の場合はこちらに入ってくるので未認証の状態をここでセット
                // ネットワークを切っている状態もこちらに入ってきたが、メッセージは同じで"No current user"であった
                dispatch(setAuthState(authState.HAS_NOT_AUTHED))
            })
    }, [])
}

/**
 * サインイン(Cognito画面使わない版)
 */
export const useSignInMyself = (userName: string, password: string) => {
    const dispatch = useDispatch()
    const navigator = useNavigator()

    // サインインを実行する関数を返す
    return useCallback(async () => {
        dispatch(toggleLoader(true))
        // cognitoに送る
        const success = await Amplify.Auth.signIn({
            username: userName,
            password: password,
        })
            .then((result) => {
                // GoogleAnalyticsUtilにユーザIDを送る
                sendEvent(result.signInUserSession.getIdToken().payload.sub, 'login')
                return true
            })
            .catch((error) => {
                console.log(error)
                // エラーコードが同じためメッセージで判定しログインエラーメッセージをセット
                if (error.message == cognitoErrorCode.LIMIT_EXCEEDED) {
                    dispatch(
                        setGlobalDialog({
                            open: true,
                            title: '',
                            mainText: messages.LOGIN_LOCK_ERROR(),
                        })
                    )
                    dispatch(setLoginErrorMessage(''))
                } else {
                    dispatch(setLoginErrorMessage(messages.LOGIN_ERROR()))
                }
                return false
            })
        dispatch(toggleLoader(false))
        if (success) {
            // ログインエラーメッセージを空文字でセット
            dispatch(setLoginErrorMessage(''))
            dispatch(setAuthState(authState.HAS_AUTHED))
            navigator(pagePath.SELECT_STATION)
        } else {
            dispatch(setAuthState(authState.HAS_NOT_AUTHED))
        }
    }, [userName, password])
}

/**
 * サインアウト
 */
export const useSignOut = () => {
    const dispatch = useDispatch()
    const navigator = useNavigator()

    // サインアウトを実行する関数を返す
    return useCallback(async () => {
        dispatch(toggleLoader(true))
        const success = await Amplify.Auth.signOut()
            .then(() => true)
            .catch(() => false)
        dispatch(toggleLoader(false))
        if (success) {
            dispatch(setAuthState(authState.HAS_NOT_AUTHED))
            navigator(pagePath.SIGN_IN)
        }
    }, [])
}
