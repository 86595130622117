import LuxonUtils from '@date-io/luxon'
import React from 'react'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useDispatch, useSelector } from 'react-redux'
import { selectDateSelector } from 'modules/pages/selectors'
import { AppDispatch } from 'store/configureStore'
import { setSelectDate } from 'modules/pages/reducers'
import { DateTime } from 'luxon'

interface props {
    className: string
}

class JaLocalizedUtils extends LuxonUtils {
    public getCalendarHeaderText(date: DateTime) {
        return this.format(date, 'MMMM月')
    }

    // ヘッダ部分のテキストを取得するメソッド
    public getDatePickerHeaderText() {
        return ''
    }
}

/**
 * 日付選択カレンダー
 * @param props
 * @constructor
 */
const Calendar: React.FC<props> = (props): React.ReactElement => {
    const selectedDate = useSelector(selectDateSelector)

    //Dispatch：他のファイルにかいてある処理を呼び出す
    const dispatch: AppDispatch = useDispatch()

    const onChangeDate = (date: MaterialUiPickersDate) => {
        if (date !== null) {
            const d = date.toJSDate()

            //日本語化
            dispatch(setSelectDate(d))
        }
    }

    return (
        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale="jaLocale">
            <KeyboardDatePicker
                variant="inline"
                format="yyyy年M月d日"
                margin="normal"
                id="date-picker-inline"
                minDate={new Date('2021-04-01')}
                maxDate={new Date()}
                value={new Date(selectedDate)}
                onChange={onChangeDate}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                    style: { color: '#ffffff', marginLeft: '-6px' },
                }}
                className={props.className}
                InputProps={{ readOnly: true }}
                autoOk={true}
            />
        </MuiPickersUtilsProvider>
    )
}
export default React.memo(Calendar)
