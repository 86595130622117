import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InitMenu, MenuMaster, StationMaster } from 'modules/masters/types'
import * as PayloadTypes from 'modules/pages/types'
import { PageState } from 'modules/pages/types'

const initialState: PageState = {
    // loaderの表示
    dispLoader: false,
    // 全体共通ダイアログ用(どの画面でも起きうるエラーの通知とか)
    dialog: {
        open: false,
        title: '',
        mainText: '',
    },
    // 画面遷移やログアウトの共通ダイアログ
    confirmationDialog: {
        open: false,
        title: '',
        mainText: '',
        cancelLabel: '',
    },
    // パスワードダイアログ
    passwordDialogOpen: false,
    passwordConfirmDialog: {
        open: false,
        userEmail: '',
    },
    dashboardUrl: '',
    selectStation: {
        routeCode: 0,
        routeName: '',
        stationCode: '',
        stationName: '',
        order: '',
    },
    selectDate: new Date().getTime(),
    selectMenu: InitMenu,
    loginErrorMessage: '',

    // パスワード変更時、ユーザーが直ぐに対応可能なエラーの場合に表示
    changePasswordErrorMessage: '',
    // パスワード変更時、再実行のため一度ログイン画面に戻る必要がある場合
    changePasswordRestartErrorMessage: '',
    // パスワード変更成功した場合
    changePasswordSuccessMessage: '',
}

// ページ全般に関するデータ
const pageSlice = createSlice({
    name: 'pages',
    initialState,
    reducers: {
        //共通通知ダイアログを開く
        setGlobalDialog: (state, action: PayloadAction<PayloadTypes.Dialog>) => {
            state.dialog = action.payload
        },
        //共通通知ダイアログを閉じる
        closeGlobalDialog: (state) => {
            state.dialog.open = false
            state.dialog.title = ''
            state.dialog.mainText = ''
        },
        //確認ダイアログを開く
        setGlobalConfirmationDialog: (state, action: PayloadAction<PayloadTypes.ConfirmationDialog>) => {
            state.confirmationDialog = action.payload
        },
        //確認ダイアログを閉じる
        closeGlobalConfirmationDialog: (state) => {
            state.confirmationDialog.open = false
            state.confirmationDialog.title = ''
            state.confirmationDialog.mainText = ''
            state.confirmationDialog.continueLabel = undefined
            state.confirmationDialog.cancelLabel = ''
            state.confirmationDialog.onClickContinue = undefined
        },
        //loaderを開閉する
        toggleLoader: (state, action?: PayloadAction<boolean>) => {
            // 開閉状態の指定がなければ前の状態と逆にする
            state.dispLoader = action ? action.payload : !state.dispLoader
        },
        //埋め込みURL設定
        setDashboardUrl: (state, action: PayloadAction<string>) => {
            state.dashboardUrl = action.payload
        },
        //選択駅表示
        // PayloadAction：Reduxのアクション
        // payload：reducerの呼び出しパラメータ
        setSelectStation: (state, action: PayloadAction<StationMaster>) => {
            state.selectStation = action.payload
        },
        //日付選択
        setSelectDate: (state, action: PayloadAction<Date>) => {
            state.selectDate = action.payload.getTime()
        },
        //メニュー
        setSelectMenu: (state, action: PayloadAction<MenuMaster>) => {
            state.selectMenu = action.payload
        },
        // ログインエラーメッセージ
        setLoginErrorMessage: (state, action: PayloadAction<string>) => {
            state.loginErrorMessage = action.payload
        },
        // パスワード再設定コード送信ダイアログ
        setPasswordDialogOpen: (state, action: PayloadAction<boolean>) => {
            state.passwordDialogOpen = action.payload
        },
        // 新パスワード設定ダイアログ
        setPasswordConfirmDialogOpen: (
            state,
            action: PayloadAction<{
                open: boolean
                userEmail: string
            }>
        ) => {
            state.passwordConfirmDialog.open = action.payload.open
            state.passwordConfirmDialog.userEmail = action.payload.userEmail
        },
        // パスワード変更エラーメッセージ
        setChangePasswordErrorMessage: (state, action: PayloadAction<string>) => {
            state.changePasswordErrorMessage = action.payload
        },
        // パスワード変更リスタートエラーメッセージ
        setChangePasswordRestartErrorMessage: (state, action: PayloadAction<string>) => {
            state.changePasswordRestartErrorMessage = action.payload
        },
        // パスワード変更成功メッセージ
        setChangePasswordSuccessMessage: (state, action: PayloadAction<string>) => {
            state.changePasswordSuccessMessage = action.payload
        },
    },
})

export default pageSlice.reducer
export const {
    setGlobalDialog,
    closeGlobalDialog,
    setGlobalConfirmationDialog,
    closeGlobalConfirmationDialog,
    toggleLoader,
    setDashboardUrl,
    setSelectStation,
    setSelectDate,
    setSelectMenu,
    setLoginErrorMessage,
    setPasswordDialogOpen,
    setPasswordConfirmDialogOpen,
    setChangePasswordErrorMessage,
    setChangePasswordRestartErrorMessage,
    setChangePasswordSuccessMessage,
} = pageSlice.actions
