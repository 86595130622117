import React from 'react'
import styles from 'styles/signIn/signIn.module.scss'
import SignInForms from 'components/parts/SignInForms'
import { AppDispatch } from 'store/configureStore'
import { useDispatch } from 'react-redux'
import { setPasswordDialogOpen } from 'modules/pages/reducers'
import { messages } from 'constants/messages'

/**
 * ログイン画面
 * @constructor
 */
const SignIn: React.FC = () => {
    const dispatch: AppDispatch = useDispatch()

    return (
        <div className={styles.container}>
            {/* タイトル */}
            <h1 className={styles.pageTitle}></h1>
            <SignInForms />
            <a className={styles.passwordForgot} onClick={() => dispatch(setPasswordDialogOpen(true))}>
                {messages.PASSWORD_FORGOT()}
            </a>
        </div>
    )
}

export default React.memo(SignIn)
