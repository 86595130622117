import React, { useEffect } from 'react'
import styles from 'styles/signIn/signIn.module.scss'
import { Button, MenuItem, Select } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { pageSelector } from 'modules/pages/selectors'
import odakyuCoIcon from 'resources/images/odakyuCoIcon.jpg'
import { AppDispatch } from 'store/configureStore'
import { setSelectStation } from 'modules/pages/reducers'
import { useNavigator } from 'hooks/common/routingHooks'
import buttonStyles from 'styles/signIn/signInForms.module.scss'
import { useCookie } from 'react-use'
import { menuMasterSelector, stationMasterSelector } from 'modules/masters/selectors'
import { StationMaster } from 'modules/masters/types'
import { stationSelectLabel } from 'constants/labels'
import { navigateTop } from 'common/commonUtil'

/**
 * 駅選択画面
 * @constructor
 */
const StationSelect: React.FC = () => {
    // 駅マスタ
    const stationList = useSelector(stationMasterSelector)
    const menuList = useSelector(menuMasterSelector)

    const dispatch: AppDispatch = useDispatch()
    // 共通ステートから値を取ってきたもの
    const selectedStation = useSelector(pageSelector).selectStation
    const onChangeStation = (station: StationMaster) => {
        dispatch(setSelectStation(station))
        // オブジェクトが文字列型で保持
        updateCookie(JSON.stringify(station))
    }

    // 決定ボタンを押下したらcookie保持
    const onSetCookieAndNavigator = () => {
        // オブジェクト型を文字列型で保持
        updateCookie(JSON.stringify(selectedStation))
        // ダッシュボード画面トップへ遷移
        navigateTop(navigator, menuList[0])
    }

    const navigator = useNavigator()

    // 駅情報をcookieに保存する
    const [station, updateCookie] = useCookie('station')

    // この画面に入ったときに動く処理（useEffect）
    useEffect(() => {
        if (station && menuList.length !== 0) {
            // ダッシュボード画面トップへ遷移
            navigateTop(navigator, menuList[0])
        } else {
            // cookieがなければ
            if (stationList.length) {
                dispatch(setSelectStation(stationList[0]))
            }
        }
    }, [])

    useEffect(() => {
        if (station && menuList.length !== 0) {
            // ダッシュボード画面トップへ遷移
            navigateTop(navigator, menuList[0])
        }
    }, [menuList])

    return (
        <div className={styles.container}>
            {/* 小田急アイコン */}
            <img src={odakyuCoIcon} alt="Logo" width="120px" />
            <form className={styles.formArea}>
                {/* 駅選択 */}
                <label className={styles.container}>{stationSelectLabel.stationSelect}</label>
                <div>
                    <Select
                        className={styles.container}
                        variant="outlined"
                        value={selectedStation.routeCode}
                        type="string"
                    >
                        {stationList.map((item) => {
                            return (
                                <MenuItem
                                    onClick={() => onChangeStation(item)}
                                    value={item.routeCode}
                                    key={item.routeCode}
                                >
                                    {item.stationName}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </div>
                <div className={buttonStyles.inputArea}>
                    <Button
                        variant="contained"
                        className={buttonStyles.signInButton}
                        // 決定ボタンを押したらcookieに駅を保存する
                        onClick={onSetCookieAndNavigator}
                        // 駅未選択時にボタンを非活性にする
                        disabled={selectedStation && !selectedStation.stationCode}
                    >
                        {stationSelectLabel.decide}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default React.memo(StationSelect)
