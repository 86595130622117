import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { Amplify } from 'aws-amplify'
import { cognitoChangePasswordExceptions } from 'constants/constants'
import { signInLabels } from 'constants/labels'
import { messages, words } from 'constants/messages'
import { useStringState } from 'hooks/common/commonHooks'
import {
    setChangePasswordRestartErrorMessage,
    setChangePasswordSuccessMessage,
    setGlobalDialog,
    setLoginErrorMessage,
    setPasswordConfirmDialogOpen,
    toggleLoader,
} from 'modules/pages/reducers'
import React from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store/configureStore'
import styles from 'styles/signIn/signInForms.module.scss'

interface Props {
    // 開閉状態
    open: boolean
    // Noのときのアクション
    onClose: () => void
}

/**
 * パスワード変更用認証コード送信ダイアログ
 * @param props
 * @constructor
 */
export const sendCodeChangePasswordDialog: React.FC<Props> = (props) => {
    const [userEmail, setUserEmail] = useStringState('')
    const dispatch: AppDispatch = useDispatch()
    const activeBtn = !userEmail.trim()

    // はじめからやり直すボタン押下時
    const returnSignIn = () => {
        setUserEmail.reset()
        props.onClose()
    }

    const sendForgotPasswordCodeAsync = async (userEmail: string) => {
        dispatch(toggleLoader(true))

        await Amplify.Auth.forgotPassword(userEmail)
            .then(() => {
                // パスワード変更ダイアログへ遷移 メールアドレスも渡す
                dispatch(setPasswordConfirmDialogOpen({ open: true, userEmail }))
                dispatch(setChangePasswordSuccessMessage(''))
                dispatch(toggleLoader(false))
            })
            .catch((error: any) => {
                console.log(error)
                // 例外オブジェクトはError型なのだが、型的には存在しないはずの"code"という項目を参照する必要がある。
                // 通常はifで型を判断したルート分岐をして型を確定させるが、今回はanyのままとした。aws-amplifyに問題がある。
                switch (error?.code) {
                    case cognitoChangePasswordExceptions.LIMIT_EXCEEDED:
                        // パスワード変更試行回数制限超え
                        dispatch(setChangePasswordRestartErrorMessage(messages.CHANGE_PASSWORD_ERROR_LIMIT_EXCEEDED()))
                        break
                    default:
                        // システムエラー（500）
                        dispatch(setGlobalDialog({ title: '', mainText: messages.SYSTEM_ERROR(), open: true }))
                        break
                }
            })
        // パスワード変更用コード送信ダイアログを閉じる(App.tsから)
        props.onClose()
        setUserEmail.reset()
        dispatch(toggleLoader(false))
        dispatch(setLoginErrorMessage(''))
    }

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent>
                <DialogContentText>
                    <span className={styles.dialogText}>
                        {messages.CHANGE_PASSWORD_DIALOG_MESSAGE()}
                        <div className={styles.inputPasswordArea}>
                            {/* メールアドレス入力 */}
                            <TextField
                                // 枠で囲まれたタイプ
                                variant="outlined"
                                // 横幅は親要素いっぱい
                                fullWidth
                                // 上に移動するラベルがつく
                                label={signInLabels.MAIL_ADDRESS}
                                // 普通のHTMLのtypeと一緒
                                type="email"
                                // ユーザーの入力補完に役立つぽい
                                autoComplete="email"
                                InputProps={{
                                    // 上限を効かせてくれる
                                    // 中のテキストフィールドに対してクラス名をつけたりもできる
                                    inputProps: { maxLength: 255 },
                                    classes: {
                                        // borderのスタイル効かせたいならこれ
                                        notchedOutline: styles.inputTextFieldOutline,
                                    },
                                }}
                                value={userEmail}
                                onChange={setUserEmail.update}
                            />
                        </div>
                    </span>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.inputPasswordArea}>
                {/* はじめからやり直す*/}
                <Button
                    className={styles.returnButton}
                    align-items="left"
                    onClick={() => returnSignIn()}
                    color="secondary"
                    autoFocus={true}
                >
                    {words.RETURN_SIGN_IN}
                </Button>
                {/* 認証コード送信*/}
                <Button
                    className={styles.signInButton}
                    align-items="center"
                    color="secondary"
                    autoFocus={true}
                    disabled={activeBtn}
                    onClick={() => sendForgotPasswordCodeAsync(userEmail)}
                    // メールアドレス入力されてないとボタンは非活性
                >
                    {words.SEND_CODE}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(sendCodeChangePasswordDialog)
