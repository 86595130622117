import { MenuMaster } from 'modules/masters/types'
import { store } from 'store/configureStore'
import { setSelectMenu } from 'modules/pages/reducers'
import { pagePath } from 'constants/paths'

/**
 * ダッシュボードトップへ遷移
 * @param navigator useNavigator
 * @param top
 */
export const navigateTop = (navigator: (to: string) => void, top: MenuMaster) => {
    const queryParam = `?id=${top.dashboardName}`
    store.dispatch(setSelectMenu(top))
    navigator(pagePath.DASHBOARD + queryParam)
}
