import { Button, TextField } from '@material-ui/core'
import { signInLabels } from 'constants/labels'
import { getNotificationLink } from 'constants/paths'
import { useSignInMyself } from 'hooks/common/authHooks'
import { useStringState, useSubmit } from 'hooks/common/commonHooks'
import { setChangePasswordRestartErrorMessage, setChangePasswordSuccessMessage } from 'modules/pages/reducers'
import {
    changePasswordRestartErrorMessageSelector,
    changePasswordSuccessMessageSelector,
    loginErrorMessageSelector,
} from 'modules/pages/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import odakyuCoIcon from 'resources/images/odakyuCoIcon.jpg'
import { AppDispatch } from 'store/configureStore'
import styles from 'styles/signIn/signInForms.module.scss'

// ログイン画面
const SignInForms: React.FC = () => {
    const [userName, setUserName] = useStringState('')
    const [password, setPassword] = useStringState('')

    const signInMyself = useSignInMyself(userName, password)
    const submitSignIn = useSubmit(signInMyself)

    const activeBtn = !(userName.trim() && password.trim())
    // ログインエラーメッセージ
    const loginErrorMessage = useSelector(loginErrorMessageSelector)
    // パスワード変更成功メッセージ
    const changePasswordSuccessMessage = useSelector(changePasswordSuccessMessageSelector)
    // パスワード変更失敗メッセージ
    const changePasswordRestartErrorMessage = useSelector(changePasswordRestartErrorMessageSelector)
    const dispatch: AppDispatch = useDispatch()

    const signIn = () => {
        signInMyself().then(() => {
            setPassword.reset()
            dispatch(setChangePasswordRestartErrorMessage(''))
            dispatch(setChangePasswordSuccessMessage(''))
        })
    }

    return (
        <div>
            <div>
                <img src={odakyuCoIcon} alt="Logo" width="120px" />
            </div>
            <form className={styles.formArea} onKeyPress={submitSignIn}>
                {/* メールアドレス入力 */}
                <div className={styles.inputArea}>
                    {/* <NormalTextField /> */}
                    <TextField
                        // 枠で囲まれたタイプ
                        variant="outlined"
                        // 横幅は親要素いっぱい
                        fullWidth
                        // 上に移動するラベルがつく
                        label={signInLabels.MAIL_ADDRESS}
                        // 普通のHTMLのtypeと一緒
                        type="email"
                        // ユーザーの入力補完に役立つぽい
                        autoComplete="email"
                        InputProps={{
                            // 上限を効かせてくれる
                            // 中のテキストフィールドに対してクラス名をつけたりもできる
                            inputProps: { maxLength: 255 },
                            classes: {
                                // borderのスタイル効かせたいならこれ
                                notchedOutline: styles.inputTextFieldOutline,
                            },
                        }}
                        onChange={setUserName.update}
                    />
                </div>

                {/* パスワード入力 */}
                <div className={styles.inputArea}>
                    <TextField
                        // 枠で囲まれたタイプ
                        variant="outlined"
                        // 横幅は親要素いっぱい
                        fullWidth
                        // 上に移動するラベルがつく
                        label={signInLabels.PASSWORD}
                        // 普通のHTMLのtypeと一緒
                        type="password"
                        InputProps={{
                            // 上限を効かせてくれる
                            // 中のテキストフィールドに対してクラス名をつけたりもできる
                            inputProps: { maxLength: 16 },
                            classes: {
                                // borderのスタイル効かせたいならこれ
                                notchedOutline: styles.inputTextFieldOutline,
                            },
                        }}
                        value={password}
                        onChange={setPassword.update}
                    />
                </div>

                {/* お知らせHTML*/}
                <div className={styles.notificationArea}>
                    <iframe src={getNotificationLink()} width="100%" frameBorder="no" />
                </div>
                {/* ログインエラーメッセージ */}
                {loginErrorMessage && <p className={`${styles.errorText}`}>{loginErrorMessage}</p>}
                {/* パスワード変更成功メッセージ */}
                {changePasswordSuccessMessage && (
                    <p className={`${styles.successText}`}>{changePasswordSuccessMessage}</p>
                )}
                {/* パスワード変更失敗メッセージ */}
                {changePasswordRestartErrorMessage && (
                    <p className={`${styles.changePasswordRestartErrorText}`}>{changePasswordRestartErrorMessage}</p>
                )}
                {/* サインインボタン */}
                <div className={styles.inputArea}>
                    <Button className={styles.signInButton} variant="contained" disabled={activeBtn} onClick={signIn}>
                        {signInLabels.SIGN_IN_BUTTON}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default React.memo(SignInForms)
