import * as React from 'react'
import styles from 'styles/header/header.module.scss'
import { MenuItem, Select } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { pageSelector } from 'modules/pages/selectors'
import { AppDispatch } from 'store/configureStore'
import { setSelectStation } from 'modules/pages/reducers'
import SideMenu from 'components/parts/SideMenu'
import Calendar from 'components/parts/Calendar'
import { useCookie } from 'react-use'
import { stationMasterSelector } from 'modules/masters/selectors'

export interface Props {
    headerRef: React.MutableRefObject<null>
}

//アプリの共通ヘッダー
const Header: React.FC<Props> = (props) => {
    // 駅マスタ
    const stationList = useSelector(stationMasterSelector)

    //駅選択
    const dispatch: AppDispatch = useDispatch()
    // 共通ステートから値を取ってきたもの
    const selectedStation = useSelector(pageSelector).selectStation
    const onChangeStation = (event: React.ChangeEvent<{ value: unknown }>) => {
        // マスタから一致するものを見つけてくる
        const stationInfo = stationList.find((item) => item.routeCode === (event.target.value as number))
        if (!stationInfo) return
        dispatch(setSelectStation(stationInfo))
        updateCookie(JSON.stringify(stationInfo))
    }

    const [station, updateCookie, deleteCookie] = useCookie('station')

    return (
        <header ref={props.headerRef}>
            <div className={styles.header}>
                {/*左詰め要素*/}
                <div className={styles.headerLeft}>
                    {/*サイドメニュー*/}
                    <SideMenu />
                    {/* 駅選択 */}
                    {/* 設定ファイルをS3に置く */}
                    <div className={styles.labelGroup}>
                        <label>選択駅</label>
                        {/* SelectとMenuItemのvalueが一致するときonChangesStationが働く */}
                        <Select
                            value={selectedStation.routeCode}
                            onChange={(e) => onChangeStation(e)}
                            type="string"
                            className={styles.stationSelect}
                        >
                            {stationList.map((item) => {
                                return (
                                    <MenuItem value={item.routeCode} key={item.routeCode}>
                                        {item.stationName}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    {/* material-ui/pickerのやり方 */}
                    <div className={styles.labelGroup}>
                        <label>日付</label>
                        <Calendar className={styles.datePicker} />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default React.memo(Header)
