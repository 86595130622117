import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { getEmbedUrl } from 'modules/pages/operations'
import { toggleLoader } from 'modules/pages/reducers'
import { pageSelector } from 'modules/pages/selectors'

/**
 * 埋め込みURL取得
 */
export const useEmbedUrlRequest = () => {
    const dispatch = useDispatch()
    const page = useSelector(pageSelector)

    return useCallback(async () => {
        dispatch(toggleLoader(true))
        await dispatch(getEmbedUrl(page.selectMenu.dashboardId))
        dispatch(toggleLoader(false))
    }, [page.selectMenu.dashboardId])
}
