// ビルド時のステージごとの名称
const STAGES = {
    LOCAL: 'local',
    DEV: 'dev',
    STG: 'stg',
    PROD: 'prod',
}

//環境変数をオブジェクトにまとめておく
export const ENV = {
    API_URL: process.env.REACT_APP_API_URL,
    AWS_REGION: process.env.REACT_APP_REGION,
    COGNITO_USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    COGNITO_WEB_CLIENT_ID: process.env.REACT_APP_WEB_CLIENT_ID,
    COGNITO_OAUTH_DOMAIN: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    COGNITO_REDIRECT_SIGN_IN: process.env.REACT_APP_REDIRECT_SIGN_IN,
    COGNITO_REDIRECT_SIGN_OUT: process.env.REACT_APP_REDIRECT_SIGN_OUT,
    AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID || '',
    AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY || '',
    AWS_DEFAULT_REGION: process.env.AWS_DEFAULT_REGION || '',
    AWS_SESSION_TOKEN: process.env.AWS_SESSION_TOKEN || '',
    DOMAIN_NAME: process.env.REACT_APP_DOMAIN_NAME,

    // 本番以外デバッグモードとしておく
    DEBUG:
        process.env.REACT_APP_STAGE === STAGES.LOCAL ||
        process.env.REACT_APP_STAGE === STAGES.DEV ||
        process.env.REACT_APP_STAGE === STAGES.STG,
}

// amplifyに渡す情報(cognito用)

export const AWS_CONFIG = {
    Auth: {
        region: ENV.AWS_REGION,
        userPoolId: ENV.COGNITO_USER_POOL_ID,
        userPoolWebClientId: ENV.COGNITO_WEB_CLIENT_ID,
        // storage: localStorage,
    },
    API: {
        endpoints: [
            {
                region: ENV.AWS_REGION,
            },
        ],
    },
}

// 認証スキップ設定（local実行のときは認証スキップ）
export const SKIP_SIGN_IN = process.env.REACT_APP_STAGE === STAGES.LOCAL
