import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiPostRequest } from 'common/httpClient'
import { ApiError, AsyncThunkConfig } from 'common/types'
import { apiPath } from 'constants/paths'
import { GetEmbedUrlRes } from './types'
import { setDashboardUrl, setGlobalDialog } from './reducers'
import { messages } from 'constants/messages'

export const getEmbedUrl = createAsyncThunk<void, string, AsyncThunkConfig>(
    'pages/getEmbedUrl',
    async (dashboardId, thunkAPI) => {
        const body = { dashboard_id: dashboardId }
        const result = await apiPostRequest<GetEmbedUrlRes, ApiError>(apiPath.GET_EMBED_URL, { body })
        if (!axios.isAxiosError(result)) {
            // 成功時
            thunkAPI.dispatch(setDashboardUrl(result.data.url))
        } else {
            // 失敗時
            thunkAPI.dispatch(setGlobalDialog({ title: '', mainText: messages.SYSTEM_ERROR(), open: true }))
        }
    }
)
