import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest } from 'common/httpClient'
import { ApiError, AsyncThunkConfig } from 'common/types'
import { apiPath } from 'constants/paths'
import { MasterState } from 'modules/masters/types'
import { setMenuMasterState, setStationMasterState } from 'modules/masters/reducers'
import { messages } from 'constants/messages'
import { setGlobalDialog, setSelectStation } from 'modules/pages/reducers'

export const getMasterData = createAsyncThunk<void, boolean, AsyncThunkConfig>(
    'pages/getMasterData',
    // 今回は使用していないが今後マスタの分割等あった際にcategory指定になる可能性があるため引数を持つ
    async (isSetStation: boolean, thunkAPI) => {
        const result = await apiGetRequest<MasterState, ApiError>(apiPath.GET_MASTER_DATA, {})
        if (!axios.isAxiosError(result)) {
            // 成功時
            // 各マスタの変数に格納
            thunkAPI.dispatch(setMenuMasterState(result.data.menu))
            thunkAPI.dispatch(setStationMasterState(result.data.station))

            // 念のため選択済みの駅のコードがなければ0番目の値を格納
            if (isSetStation && result.data.station.length) {
                thunkAPI.dispatch(setSelectStation(result.data.station[0]))
            }
        } else {
            // 失敗時
            thunkAPI.dispatch(setGlobalDialog({ title: '', mainText: messages.SYSTEM_ERROR(), open: true }))
        }
    }
)
