import { ENV } from 'setttings/settings'

// ページとAPIのパスを定義
export const pagePath = {
    ROOT: '/',
    SIGN_IN: '/sign-in',
    SELECT_STATION: '/select-station',
    DASHBOARD: '/dashboard',
}

export const apiPath = {
    GET_EMBED_URL: '/get-embed-url',
    GET_MASTER_DATA: '/get-master-data',
}

// S3バケットのパスを定義
export const getNotificationLink = () => `https://${ENV.DOMAIN_NAME}/asset/notification.html`
