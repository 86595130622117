import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { toggleLoader } from 'modules/pages/reducers'
import { getMasterData } from 'modules/masters/operations'
import { selectStationSelector } from 'modules/pages/selectors'
import { useCookie } from 'react-use'

/**
 * 埋め込みURL取得
 */
export const useMasterDataRequest = () => {
    const dispatch = useDispatch()
    const selectedStation = useSelector(selectStationSelector)
    const [station] = useCookie('station')

    return useCallback(async () => {
        // cookieが存在しないかつ選択済みのstationCodeが空文字の場合マスタ取得後に駅マスタの1番目の値を格納
        const isSetStation = !station && selectedStation && !selectedStation.stationCode

        dispatch(toggleLoader(true))
        // 今回はcategoryを空文字で指定
        await dispatch(getMasterData(isSetStation))
        dispatch(toggleLoader(false))
    }, [])
}
