import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/configureStore'

export const pageSelector = (state: RootState) => state.pages

export const dialogSelector = createSelector([pageSelector], (page) => page.dialog)
export const confirmationDialogSelector = createSelector([pageSelector], (page) => page.confirmationDialog)
export const loaderSelector = createSelector([pageSelector], (page) => page.dispLoader)
export const selectStationSelector = (state: RootState) => state.pages.selectStation

export const selectDateSelector = (state: RootState) => state.pages.selectDate
export const dashboardUrlSelector = (state: RootState) => state.pages.dashboardUrl
export const loginErrorMessageSelector = (state: RootState) => state.pages.loginErrorMessage
export const passwordDialogOpenSelector = (state: RootState) => state.pages.passwordDialogOpen
export const passwordDialogConfirmSelector = (state: RootState) => state.pages.passwordConfirmDialog
export const changePasswordErrorMessageSelector = (state: RootState) => state.pages.changePasswordErrorMessage
export const changePasswordRestartErrorMessageSelector = (state: RootState) =>
    state.pages.changePasswordRestartErrorMessage
export const changePasswordSuccessMessageSelector = (state: RootState) => state.pages.changePasswordSuccessMessage
