import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as PayloadTypes from 'modules/masters/types'

const initialState: PayloadTypes.MasterState = {
    // メニューマスタ
    menu: [],
    // 駅マスタ
    station: [],
}

const usersSlice = createSlice({
    name: 'masters',
    initialState,
    reducers: {
        // メニューマスタセット
        setMenuMasterState: (state, action: PayloadAction<PayloadTypes.MenuMaster[]>) => {
            state.menu = action.payload
        },
        // 駅マスタセット
        setStationMasterState: (state, action: PayloadAction<PayloadTypes.StationMaster[]>) => {
            state.station = action.payload
        },
    },
})

export default usersSlice.reducer
export const { setMenuMasterState, setStationMasterState } = usersSlice.actions
