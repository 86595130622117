import React, { useState } from 'react'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { useNavigator } from 'hooks/common/routingHooks'
import { pagePath } from 'constants/paths'
import { useSignOut } from 'hooks/common/authHooks'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'store/configureStore'
import { closeGlobalConfirmationDialog, setGlobalConfirmationDialog, setSelectMenu } from 'modules/pages/reducers'
import { pageSelector } from 'modules/pages/selectors'
import styles from 'styles/sideMenu/sideMenu.module.scss'
import { messages, words } from 'constants/messages'
import { useCookie } from 'react-use'
import { menuMasterSelector } from 'modules/masters/selectors'
import { MenuMaster } from 'modules/masters/types'
import { sideMenuLabel } from 'constants/labels'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
        },
        menuButton: {
            marginLeft: 0,
        },
        hide: {
            visibility: 'hidden',
        },
    })
)

/**
 * サイドメニュー
 * @constructor
 */
const SideMenu: React.FC = () => {
    // メニューマスタ
    const menuList = useSelector(menuMasterSelector)

    const [open, setOpen] = useState(false)
    const toggleOpen = () => {
        setOpen(!open)
    }
    const handleDrawerOpen = () => {
        setOpen(true)
    }
    const localStyles = useStyles()
    const navigator = useNavigator()

    const signOut = useSignOut()

    const dispatch: AppDispatch = useDispatch()
    // 共通ステートから値を取ってきたもの
    const selectedMenu = useSelector(pageSelector).selectMenu
    const onChangeMenu = (menu: MenuMaster) => {
        dispatch(setSelectMenu(menu))

        //サイドメニューを閉じる
        toggleOpen()
        const queryParam = `?id=${menu.dashboardName}`
        navigator(pagePath.DASHBOARD + queryParam)
    }

    const checkSignOut = () => {
        dispatch(
            setGlobalConfirmationDialog({
                open: true,
                title: '',
                mainText: messages.CONFIRM_LOGOUT(),
                continueLabel: words.OK,
                cancelLabel: words.CANCEL,
                onClickContinue: doSignOut,
            })
        )
    }

    const doSignOut = () => {
        signOut().then()
        dispatch(closeGlobalConfirmationDialog())
    }

    const [station, updateCookie, deleteCookie] = useCookie('station')

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(localStyles.menuButton, open && localStyles.hide)}
            >
                <MenuIcon style={{ color: '#ffffff' }} />
            </IconButton>
            <Drawer anchor="left" open={open} onClose={toggleOpen} className={styles.menuArea}>
                <div className={styles.closeButtonArea}>
                    <IconButton
                        color="inherit"
                        aria-label="close drawer"
                        edge="start"
                        className={styles.closeButton}
                        onClick={toggleOpen}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <List className={styles.dashboardMenu}>
                    {menuList.map((item) => {
                        return (
                            <ListItem
                                value={item.menuId}
                                key={item.menuId}
                                className={`${styles.pointer} ${
                                    selectedMenu.menuId == item.menuId ? styles.active : ''
                                }`}
                                onClick={() => onChangeMenu(item)}
                            >
                                <span>{item.menuName}</span>
                            </ListItem>
                        )
                    })}
                </List>
                <List>
                    <ListItem
                        button
                        onClick={() => {
                            deleteCookie()
                            navigator(pagePath.SELECT_STATION)
                        }}
                    >
                        <ListItemText primary={sideMenuLabel.stationSelect} />
                    </ListItem>
                    <ListItem button onClick={checkSignOut}>
                        <ListItemText primary={sideMenuLabel.logout} />
                    </ListItem>
                </List>
            </Drawer>
        </>
    )
}
export default React.memo(SideMenu)
