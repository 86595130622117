export const messages = {
    LOGIN_ERROR: () => 'メールアドレスもしくはパスワードが違います。',
    LOGIN_LOCK_ERROR: () => 'アカウントがロックされました。\n15分後にやりなおしてください。',
    CONFIRM_LOGOUT: () => 'ログアウトしますか？',
    PASSWORD_FORGOT: () => 'パスワードを変更しますか？',
    SYSTEM_ERROR: () => 'システムエラーです。',
    COOKIE_INVALID_ERROR: () =>
        'ブラウザの設定で、Cookieがブロックされています。設定からCookieを許可してください。許可したあと、再度本サイトにアクセスしページの更新をしてください。',
    CHANGE_PASSWORD_SUCCESS: () => 'パスワードの変更が完了しました。',
    CHANGE_PASSWORD_ERROR_NOT_MATCH: () => '新しいパスワードと再入力したパスワードが一致しません',
    CHANGE_PASSWORD_ERROR_INCOMPLETE_INPUT: () =>
        '入力項目に不備があります。認証コードはメールに記載されているものと一致しているか確認してください。パスワードは記載の条件を満たしてください。',
    CHANGE_PASSWORD_ERROR_EXPIRED_CODE: () => '認証コードの有効期限が切れました。認証コードを再取得してください。',
    CHANGE_PASSWORD_ERROR_LIMIT_EXCEEDED: () =>
        'パスワード変更の試行回数制限を超過しました。1時間後に認証コードの取得からやり直してください。',
    CHANGE_PASSWORD_DIALOG_MESSAGE: () => 'パスワード変更のためメールアドレスを入力し認証コードを受け取ってください。',
    CHANGE_PASSWORD_DIALOG_POLICY:
        () => `ご指定のメールアドレス宛てに認証コードを送信しました。認証コードと新しいパスワードを入力し、パスワードを再設定してください。
※パスワードは8文字以上で記入してください。
※利用できる文字は、半角英数字、半角記号です。
※大文字、小文字、数字、記号それぞれ最低1つ使用してください。`,
}

export const words = {
    OK: 'OK',
    CANCEL: 'キャンセル',
    CLOSE: '閉じる',
    BACK: '戻る',
    SEND: '送信',
    SEND_CODE: '認証コード送信',
    RESET: '再設定する',
    RETURN_SIGN_IN: 'はじめからやり直す',
}
