import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UsersState } from './types'
import { authState } from 'constants/constants'

const initialState: UsersState = {
    // ユーザーID
    id: '',
    // 表示名称
    displayName: '',
    // 認証状態
    hasAuthed: authState.SUSPEND,
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        // 認証状態を変更
        setAuthState: (state, action: PayloadAction<number>) => {
            state.hasAuthed = action.payload
        },
    },
})

export default usersSlice.reducer
export const { setAuthState } = usersSlice.actions
