import ReactGA from 'react-ga4'

/**
 * Google analyticsにユーザIDを送る
 * @param userId 送信するユーザID
 * @param eventName イベント名
 */
export const sendEvent = (userId: string, eventName: string) => {
    ReactGA.gtag('js', new Date())

    // ログインしたユーザID
    ReactGA.gtag('set', 'user_properties', {
        user_id: userId,
    })
    // ログインした人のイベントを送信
    ReactGA.gtag('event', eventName)
}
