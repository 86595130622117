import { DateTime } from 'luxon'

/**
 * 現在日時取得
 */
export const getDatetimeNow = () => DateTime.local().setLocale('ja')

/**
 * 日付変換（UnixTime -> 日付文字列
 * @param val UnixTime
 * @param format 日付フォーマット
 * @return string 変換した文字列
 */
export const getFormatDate = (val: number, format: string): string => {
    return DateTime.fromMillis(val).toUTC().toFormat(format)
}
